import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import { ProjectActions, TranslationActions } from '../../store';
import Heading from '../../components/Heading'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Paper } from '@material-ui/core';
import CircularIntegration from '../../components/CircularIntegration'
import Tile from '../../components/Tile'
import Tabs from './Tabs'

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import ConfigForm from './ConfigForm'


const styles = {
  Paper: {
    padding: 20,
    marginTop: 10,
    marginBottom: 10,
    height: 450,
    overflowY: 'auto'
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  formControl: {
    margin: theme.spacing(3),
  },
  textHelper:{
    color: 'red',
    fontSize: 18

  }
}));

const Export = ({ projectId,
  isAdmin, 
  exportProjectTranslations, 
  getOrCreateConfigProject,
  setConfigProperty,
  updateConfigProject,
  config,
  error,
  docSavedStatus,
  setDocStatus,
  cleanUp}) => {

  useEffect(() => {
    getOrCreateConfigProject(configId);

    return () => {
      cleanUp();
    };
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();

  const [configId, setConfigId] = useState('dev');
  const newConfig = {apiKey:'',authDomain:'', databaseURL:'', projectId:'',storageBucket:''};

  const configChange = e => {
    setConfigId(e.target.value);
    getOrCreateConfigProject(e.target.value, newConfig);
  };

  const handleFormChange = e => {
    setConfigProperty({
      [e.target.id]: e.target.value
      });
    setDocStatus(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
    updateConfigProject(configId, config);
  };
  

  return (
    <div className={classes.root}>
      <Heading children={projectId} size={5} />
      <Tabs projectId={projectId} tabvalue={1} />
      
      <div className={classes.root}>
      {isAdmin ? 
      <Tile className={classes.root} title="Choose configuration to export:" >
      <Grid container>
              <Grid item sm={3}>
                <Paper style={styles.Paper}>
                  <FormControl component="fieldset" className={classes.formControl}>
                    <RadioGroup aria-label="gender" name="gender1" value={configId} onChange={configChange}>
                      <FormControlLabel value="dev" control={<Radio />} label="development" />
                      <FormControlLabel value="staging" control={<Radio />} label="staging" />
                      <FormControlLabel
                        value="prod"
                        control={<Radio />}
                        label="production"
                      />
                    </RadioGroup>
                  </FormControl>
                </Paper>
              </Grid>
              <Grid item sm>
                <Paper style={styles.Paper}>
                  <Typography
                    variant="subtitle1">
                      {configId}
                  </Typography>
                    
                  <ConfigForm placeholder="value" handleSubmit={handleSubmit} handleChange={handleFormChange} docu={config} btnDisabled={docSavedStatus} />
                  {error && (
                      <div>
                        <Typography color="error">{error.code}</Typography>
                      </div>
                    )}
                </Paper>
              </Grid>
              </Grid>
        
            <FormHelperText>Press to Export</FormHelperText>
            <CircularIntegration param={configId} promise={exportProjectTranslations} />
            <FormHelperText className={classes.textHelper}>Important Note: Create "atobot@ato-gear.com" account with translator permission to export to this project</FormHelperText>
          </Tile>

     
       : <Tile className={classes.root} title="Permission required" />}
        </div>
      

      
    </div>
  );
};

const mapStateToProps = ({ auth, project }) => ({
  isAdmin: auth.isAdmin,
  loading: auth.loading,
  config: project.config,
  error: project.error,
  docSavedStatus: project.docSavedStatus
});


const mapDispatchToProps = (dispatch, ownProps) => {
  const { exportProjectTranslations } = TranslationActions;
  const {getOrCreateConfigProject, setConfigProperty, updateConfigProject, cleanUp, setDocStatus} = ProjectActions;
  const {
    match: {
      params: { projectId }
    }
  } = ownProps;
  return {
    dispatch,
    projectId: projectId,
    exportProjectTranslations: (env) => dispatch(exportProjectTranslations(projectId, env)),
    getOrCreateConfigProject: (configId, newConfig) => dispatch(getOrCreateConfigProject(projectId, configId, newConfig)),
    setConfigProperty: (property) => dispatch(setConfigProperty(property)),
    updateConfigProject: (configId, configProperties) => dispatch(updateConfigProject(projectId, configId, configProperties)),
    setDocStatus: (status) => dispatch(setDocStatus(status)),
    cleanUp: () => dispatch(cleanUp())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Export)