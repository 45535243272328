import { getFirestorePipe } from 'ato-broker';
import {
  projectsPath,
  getScreensPath,
  getConfigPath
} from '../util/collectionPaths';

/**
 * The Project service that exports functions for fetching, manipulating and deleting the Project Collection and it's Documents.
 *
 * @class
 * @category services
 * @alias ProjectService
 * @param {Service} service A generic service instance.
 * @returns {Object} The Project service.
 */
const getProjectService = () => {
  const firestore = getFirestorePipe();

  /**
   * Get Projects.
   *
   * @returns {Promise<Array<Object>>} A Promise that resolves to an Array of Projects.
   */
  const getProjects = () =>
    firestore.getDocuments(projectsPath, {});
  
  /**
   * Get screens for specific project.
   *
   * @param {String} projectId The Project id.
   * @returns {Promise<Object>} A Promise that resolves to the Project if it exists.
   */
  const getScreens = (projectId) =>
    firestore.getDocuments(getScreensPath(projectId),{});
  
  /**
   * Add config for project.
   *
   * @param {String} projectId The Project id.
   * @param {String} configId The  Config id.
   * @returns {Promise<Object>} A Promise that resolves to the Config if it exists.
   */
  const addConfigProject = (projectId, configId, configProperties) =>
    firestore.addDocumentWithId(getConfigPath(projectId), configId, configProperties);
  
  
  /**
   * Get config for project.
   * addDocumentWithId
   * @param {String} projectId The Project id.
   * @param {String} configId The  Config id.
   * @returns {Promise<Object>} A Promise that resolves to the Config if it exists.
   */
  const getConfigProject = (projectId, configId) =>
    firestore.getDocument(getConfigPath(projectId), configId);
  
  /**
   * Update config for project.
   * 
   * @param {String} projectId The Project id.
   * @param {String} configId The  Config id.
   * @returns {Promise} returns a promise, resolves to true if edit is successful
   */
  const updateConfigProject = (projectId, configId, configProperties) =>{
    return firestore.editDocument(getConfigPath(projectId), configId, configProperties)};
  
  /**
   * Update Project Statistics.
   *
   * @param {String} projectId The Project id.
   * @returns {Promise<Object>} A Promise that resolves.
   */

  const updateProjectStat = (projectId, diff) => 
     firestore.getDocument(projectsPath, projectId)
    .then(doc => {
      doc.translated = doc.translated + diff;
      return firestore.editDocument(projectsPath, projectId, doc);
    }
  );
  
  /**
   * Update Screen Statistics.
   *
   * @param {String} projectId The Project id.
   * @param {String} screenId The  Screen id.
   * @returns {Promise<Object>} A Promise that resolves.
   */
  
  const updateScreenStat = (projectId, screenId, diff) =>
    firestore.getDocument(getScreensPath(projectId), screenId)
    .then(doc => {
      doc.translated = doc.translated + diff;
      return firestore.editDocument(getScreensPath(projectId), screenId, doc);
    });
  

  return {
    getProjects,
    getScreens,
    updateProjectStat,
    updateScreenStat,
    addConfigProject,
    getConfigProject,
    updateConfigProject
  };
};

export default getProjectService;
