import React from 'react';
import { TextField } from '@material-ui/core';

const textFieldProps = {
  fullWidth: true,
  margin: 'normal',
  variant: 'outlined'
};

const TextInput = ({ input, meta, maxLength, ...rest }) => {
  const showError =
    ((meta.submitError && !meta.dirty) || meta.error) && meta.touched;

  return (
    <TextField
      {...textFieldProps}
      {...rest}
      {...input}
      error={showError}
      helperText={showError ? meta.error || meta.submitError : undefined}
      inputProps={{ maxLength }}
    />
  );
};

export default TextInput;
