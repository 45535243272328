import React from 'react'
import {TextField, Button} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';


const TranslationValues = ({translationkey, handleSubmit, handleChange, btnDisabled}) => {
  return (
    <div>
        <form onSubmit={handleSubmit} noValidate>
      { translationkey && Object.keys(translationkey).filter(key=> key !=="id").map(key => {
        return (
          <div key={key}>
           
                <TextField
                        key={translationkey.id}
                        id={key}
                        onChange={handleChange}
                        label={key}
                        style={{ margin: 5 }}
                        placeholder="Translate"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        defaultValue={translationkey[key]}
                        InputLabelProps={{
                            shrink: true,
                            style: {fontSize: 22}
                        }}
                    />
            
          </div>
        )
      })}
      {translationkey ? <Button
        variant="contained"
        color="primary"
        size="large"
        startIcon={<SaveIcon />}
        disabled={btnDisabled}
        type="submit">
        Save
    </Button> : "..Choose a Key to Translate"}
    
      </form>
    </div>
  )
}

export default (TranslationValues)