import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check'
import AddToHomeScreenIcon from '@material-ui/icons/AddToHomeScreen';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const CircularIntegration = props => {
  const { promise, param } = props;
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const timer = React.useRef();

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  React.useEffect(() => {
    return () => {
      // eslint-disable-next-line
      clearTimeout(timer.current);
    };
  }, []);

  const handleButtonClick = () => {
    setLoading(true);
    setMessage();
    promise(param).then(res => {
        let data = res.payload.data;
        if (!data) {
            setLoading(false);
            setMessage(" Internal Error");
            return
        }
        
        setSuccess(data.success);
        if(!data.success)
        {
            setMessage(data.message.code);
        }
        setLoading(false);
        setTimeout(() => {
            setSuccess(false);
            setMessage();
          }, 5000);
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Fab
          aria-label="export"
          color="primary"
          className={buttonClassname}
          disabled={loading}
          onClick={handleButtonClick}
        >
          {success ? <CheckIcon /> : <AddToHomeScreenIcon />}
        </Fab>
        {message}
        {loading && <CircularProgress size={68} className={classes.fabProgress} />}
      </div>
    </div>
  );
}

export default CircularIntegration;