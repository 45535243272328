import React from 'react'
import {TextField, Button} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';


const ConfigForm = ({docu, placeholder, handleSubmit, handleChange, btnDisabled}) => {
  return (
    <div>
        <form onSubmit={handleSubmit}>
      { docu && Object.keys(docu).filter(key=> key !=="id").map(key => {
        return (
          <div key={key}>
           
                <TextField
                        key={docu.id}
                        id={key}
                        onChange={handleChange}
                        label={key}
                        style={{ margin: 5 }}
                        placeholder={placeholder}
                        fullWidth
                        required
                        margin="normal"
                        variant="outlined"
                        defaultValue={docu[key]}
                        InputLabelProps={{
                            shrink: true,
                            style: {fontSize: 22}                   
                        }}
                    />
            
          </div>
        )
      })}
      {docu ? <Button
        variant="contained"
        color="primary"
        size="large"
        startIcon={<SaveIcon />}
        disabled={btnDisabled}
        type="submit">
        Save
    </Button> : null}
    
      </form>
    </div>
  )
}

export default (ConfigForm)