export default {
  SET_DOC_STATUS: 'SET_DOC_STATUS',
  GET_TRANSLATION_KEYS: 'GET_TRANSLATION_KEYS',
  GET_TRANSLATION_KEY: 'GET_TRANSLATION_KEY',
  UPDATE_TRANSLATION_KEY_SUCCESS: 'UPDATE_TRANSLATION_KEY_SUCCESS',
  UPDATE_TRANSLATION_KEY_FAILURE: 'UPDATE_TRANSLATION_KEY_FAILURE',
  EXPORT_PROJECT_TRANSLATIONS_SUCCESS: 'EXPORT_PROJECT_TRANSLATIONS_SUCCESS',
  EXPORT_PROJECT_TRANSLATIONS_FAILURE: 'EXPORT_PROJECT_TRANSLATIONS_FAILURE',
  RESET: 'RESET'
  };
  