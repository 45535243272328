import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { Reducer as auth } from './auth';
import { Reducer as project } from './project';
import { Reducer as translation } from './translation';


const initStore = () => {
  const store = createStore(
    combineReducers({
        auth,
        project,
        translation
    }),
    {},
    composeWithDevTools(applyMiddleware(thunk))
  );

  return store;
};

export default initStore;
