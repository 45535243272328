import { getFirebaseFunctionsPipe } from 'ato-broker';

/**
 * The Functions service that exports a function for calling cloud functions in Firebase.
 *
 * @class
 * @category services
 * @alias FunctionsService
 * @returns {Object} The Functions service.
 */
const getFunctionService = () => {
  const functions = getFirebaseFunctionsPipe();

  /**
   * Call a cloud function
   *
   * @param {String} name The name of the function you want to call.
   * @param {Object} params An object containing the parameters for the function.
   * @returns {Promise} A Promise which resolves when the function is executed. Resolves with information about the function status.
   */
  const callCloudFunction = (name, params) =>
    functions.callCloudFunction(name, params).catch(e => {
      console.error(e);
      throw e;
    });

  return {
    callCloudFunction
  };
};

export default getFunctionService;
