import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    background: "default",
    borderBottom: '1px solid #e8e8e8',
  },
});

const CenteredTabs = props => {
  const { projectId, tabvalue } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(tabvalue);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        
      >
        <Tab label="Screens" component={Link} to={'/project/' + projectId} />
        <Tab label="Export" component={Link} to={'/project/' + projectId + '/export'} />
      </Tabs>
    </div>
  );
}


export default CenteredTabs;