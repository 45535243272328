import React, {useState} from 'react';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import { getFirebaseStoragePipe } from 'ato-broker';
import ImageUpload from '../../components/ImageUpload'
import { connect } from 'react-redux'


const useStyles = makeStyles({
    card: {
      maxWidth: 345,
      height: 550,
    },
    media: {
      height: 130,
    },
  });
const Mobile = ({ isAdmin, params }) => {
  const storage = getFirebaseStoragePipe();
  const [url, setUrl] = useState('');
  const classes = useStyles();
  const { projectId, screenId, keyId } = params;
  var filepath = keyId ? `screenshots/${projectId}/${screenId}_${keyId}.PNG`: `screenshots/${projectId}/${screenId}.PNG`;
  

  try{
  storage.getLinkForFile(filepath).then(url => {
      setUrl(url);
  }).catch( () => {
    filepath = `screenshots/${projectId}/${screenId}.PNG`;
    storage.getLinkForFile(filepath).then(url => {
      setUrl(url);
  }).catch(e => console.log(e))
  })
}catch{}



    
    return (
        <div>
          {isAdmin ? 
          <ImageUpload storage={storage} params={params} setUrl={setUrl}  />
          :null}
            <Card className={classes.card}>
     
      <img src={url || 'http://via.placeholder.com/345x550'} alt="Smiley face" height="100%" width="100%"/>
      
    </Card>
           
        </div>
    );
};

const mapStateToProps = ({ auth }) => ({
  isAdmin: auth.isAdmin,
});

export default connect(mapStateToProps)(Mobile)
