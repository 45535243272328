import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    marginTop: 1,
    marginRight: theme.spacing(1)
  }
}));

const BreadcrumbIcon = ({ children }) => {
  const classes = useStyles();

  return <span className={classes.icon}>{children}</span>;
};

BreadcrumbIcon.propTypes = {
  children: PropTypes.any.isRequired
};

export default BreadcrumbIcon;
