import Types from './actionTypes';

const setDocStatus = (status) => ({
  type: Types.SET_DOC_STATUS,
  payload: status
});

const setConfigProperty = (property) => ({
  type: Types.SET_CONFIG_PROPERTY,
  payload: property
});

const getProjectsSuccess = projects => ({
  type: Types.GET_PROJECTS,
  payload: projects
});

const getScreensSuccess = screens => ({
  type: Types.GET_SCREENS,
  payload: screens
});

const updateProjectStatSuccess = project => ({
  type: Types.UPDATE_PROJECT_STAT_SUCCESS,
  payload: project
});

const updateScreenStatSuccess = screen => ({
  type: Types.UPDATE_SCREEN_STAT_SUCCESS,
  payload: screen
});

const addConfigProjectSuccess = () => ({
  type: Types.ADD_CONFIG_PROJECT_SUCCESS
});

const addConfigProjectFailure = () => ({
  type: Types.ADD_CONFIG_PROJECT_FAILURE
});

const getConfigProjectSuccess = (config) => ({
  type: Types.GET_CONFIG_PROJECT_SUCCESS,
  payload: config
});

const getConfigProjectFailure = () => ({
  type: Types.GET_CONFIG_PROJECT_FAILURE
});

const updateConfigProjectSuccess = (config) => ({
  type: Types.UPDATE_CONFIG_PROJECT_SUCCESS,
  payload: config
});

const updateConfigProjectFailure = (error) => ({
  type: Types.UPDATE_CONFIG_PROJECT_FAILURE,
  payload: error
});

const cleanUp = () => ({
  type: Types.RESET
});



export {
  setDocStatus,
  setConfigProperty,
  getProjectsSuccess,
  getScreensSuccess,
  updateProjectStatSuccess,
  updateScreenStatSuccess,
  addConfigProjectSuccess,
  addConfigProjectFailure,
  getConfigProjectSuccess,
  getConfigProjectFailure,
  updateConfigProjectSuccess,
  updateConfigProjectFailure,
  cleanUp
};
