import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { ProjectActions } from '../../store';
import ScreenSummary from './ScreenSummary'
import Heading from '../../components/Heading'
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'
import { Grid } from '@material-ui/core';
import Tabs from './Tabs'


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}));

const Screen = ({getScreens, screens, projectId}) => {

  useEffect(() => {
    getScreens();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();

  

  return (
    <div className={classes.root}>
      <Heading children={projectId} size={5} />
      <Tabs projectId={projectId} tabvalue={0} />
      
      <div className={classes.content}>
        <Grid
          container
          spacing={3}
        >
          
          { screens && screens.map(screen => (
            <Grid
              item
              key={screen.id}
              lg={4}
              md={6}
              xs={12}
            >
              <Link to={'/project/' + projectId + '/screen/' + screen.id} key={screen.id}>
            <ScreenSummary screen={screen} />
          </Link>
            </Grid>
          ))}
        </Grid>
      </div>
      
    </div>
  );
};

const mapStateToProps = ({ auth, project }) => ({
  loading: auth.loading,
  screens: project.screens
});


const mapDispatchToProps = (dispatch, ownProps) => {
  const { getScreens } = ProjectActions;
  const {
    match: {
      params: { projectId }
    }
  } = ownProps;
  return {
    dispatch,
    getScreens: () => dispatch(getScreens(projectId)),
    projectId: projectId,
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Screen)