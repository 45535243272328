import { getAuthService } from '../../services';
import * as Actions from './actions';

/**
 * @category store
 * @module AuthStore
 */

/**
 * @private
 *
 * Check to see if a user has been signed in previously.
 */
const init = () => (dispatch, getState) => {
  const authService = getAuthService();

  return authService.onAuthStateChanged(user => {   
    
    const {
      auth: { authUser, loading }
    } = getState();

    if (user && !authUser) {

     user.getIdTokenResult()
      .then((idTokenResult) => {
        // Confirm the user is an Admin.
        let isAdmin = false;
        if (!!idTokenResult.claims.admin) {
          isAdmin = true;
        }

        dispatch(
          Actions.setAuthUser({
            uid: user.uid,
            email: user.email,
            isAdmin
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });

      
    }

    if (!user) dispatch(Actions.setAuthUser(undefined));

    if (loading) dispatch(Actions.setLoading(false));
  });
};

/**
 * Sign in with an email and password.
 *
 * @param {String} email The email.
 * @param {String} password The password.
 * @returns {Promise} A Promise which resolves when it signed in successfully.
 */
const signIn = (email, password) => (dispatch, _getState) => {
  const authService = getAuthService();

  dispatch(Actions.setLoading(true));

  return authService.signIn(email, password).catch(e => {
    if (e.code === 'auth/wrong-password') {
      e.message = 'Invalid credentials.';
      dispatch(Actions.setError(e.message));
      dispatch(Actions.setLoading(false));
    }
  });
};

/**
 * Sign out.
 *
 * @returns {Promise} A Promise which resolves when it signed out successfully.
 */
const signOut = () => (dispatch, _getState) => {
  const authService = getAuthService();

  dispatch(Actions.setLoading(true));

  return authService.signOut().catch(e => {
    throw e;
  });
};

export { init, signIn, signOut };
