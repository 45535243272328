import { getFirebaseAuth } from 'ato-broker';

/**
 * The Auth service that exports functions for signing in and out.
 *
 * @class
 * @category services
 * @alias AuthService
 * @returns {Object} The Auth service.
 */
const getAuthService = () => {
  const auth = getFirebaseAuth();

  /**
   * Sign in with an email and password.
   *
   * @param {String} email The email.
   * @param {String} password The password.
   * @returns {Promise} A Promise which resolves when it signed in successfully.
   */
  const signIn = (email, password) =>
    auth.signInWithEmailAndPassword(email, password).catch(e => {
      if (e.code === 'auth/wrong-password') e.message = 'Invalid credentials.';
      console.error(e);
      throw e;
    });

  /**
   * Sign out.
   *
   * @returns {Promise} A Promise which resolves when it signed out successfully.
   */
  const signOut = () =>
    auth.signOut().catch(e => {
      console.error(e);
      throw e;
    });

  /**
   * Add a callback to the authStateChanged event.
   *
   * @param {Function} callback The callback function to be executed when the authStateChanged event occurs.
   */
  const onAuthStateChanged = callback => auth.onAuthStateChanged(callback);

  return {
    signIn,
    signOut,
    onAuthStateChanged
  };
};

export default getAuthService;
