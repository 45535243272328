import React from 'react'
import TaskProgress from '../../components/TaskProgress'

const ProjectSummary = ({project}) => {
  return (
    <div className="card z-depth-0 project-summary">
      <TaskProgress title={project.id} done={project.translated} total={project.total} />
    </div>
  )
}

export default ProjectSummary
