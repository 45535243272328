import React from 'react'
import SignedInLinks from './SignedInLinks'
import SignedOutLinks from './SignedOutLinks'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export const Navbar = ({ authUser, loading }) => {
  const classes = useStyles();
  const links = authUser ? <SignedInLinks  /> : <SignedOutLinks />;
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
          </IconButton>
          <Typography variant="h5" className={classes.title}>
            localario
          </Typography>
          {links}
        </Toolbar>
      </AppBar>
    </div>
  )
}

const mapStateToProps = ({ auth }) => ({
  authUser: auth.authUser,
  loading: auth.loading
});

export default connect(mapStateToProps)(Navbar)
