import React from 'react';
import PropTypes from 'prop-types';
import { Route, matchPath } from 'react-router-dom';
import { Breadcrumbs as MuiBreadcrumbs } from '@material-ui/core';
import Breadcrumb from './Breadcrumb';

const Breadcrumbs = ({ routes }) => (
  <Route>
    {({ location }) => {
      const pathNames = location.pathname.split('/').filter(p => p);

      return (
        <MuiBreadcrumbs aria-label="Breadcrumbs">
          {pathNames.map((_pathName, index) => {
            const last = index === pathNames.length - 1;
            const path = `/${pathNames.slice(0, index + 1).join('/')}`;
            const route = {
              ...routes.find(route => {
                const match = matchPath(path, { path: route.path });

                return match ? match.isExact : false;
              }),
              path
            };

            return <Breadcrumb key={index} last={last} route={route} />;
          })}
        </MuiBreadcrumbs>
      );
    }}
  </Route>
);

Breadcrumbs.propTypes = {
  routes: PropTypes.array.isRequired
};

export default Breadcrumbs;
