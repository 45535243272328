import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { ProjectActions } from '../../store';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ProjectSummary from '../project/ProjectSummary'
import { Link } from 'react-router-dom'
import Heading from '../../components/Heading'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}));

const Dashboard = ({getProjects, projects}) => {

  useEffect(() => {
    getProjects();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();

  

  return (
    <div className={classes.root}>
      <Heading children="Projects" size={5} />
      <div className={classes.content}>
      
        <Grid
          container
          spacing={3}
        >
          
          { projects && projects.map(project => (
            <Grid
              item
              key={project.id}
              lg={4}
              md={6}
              xs={12}
            >
              <Link to={'/project/' + project.id} key={project.id}>
            <ProjectSummary project={project} />
          </Link>
            </Grid>
          ))}
        </Grid>
      </div>
      
    </div>
  );
};

const mapStateToProps = ({ auth, project }) => ({
  authUser: auth.authUser,
  loading: auth.loading,
  projects: project.projects
});



const mapDispatchToProps = dispatch => {
  const { getProjects } = ProjectActions;

  return {
    dispatch,
    getProjects: () => dispatch(getProjects())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)