import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  }
}));

const SplashScreen = () => {
  const classes = useStyles();

  return <div className={classes.loader}>Loading...</div>;
};

export default SplashScreen;
