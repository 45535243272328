import Types from './actionTypes';

const setAuthUser = authUser => ({
  type: Types.SET_AUTH_USER,
  payload: authUser
});

const setError = error => ({
  type: Types.SET_ERROR,
  payload: error
});

const setLoading = loading => ({
  type: Types.SET_LOADING,
  payload: loading
});

export { setAuthUser, setError, setLoading };
