import { joinPaths } from './functions';
const projectsPath = 'projects';
const screensPath = 'screens';
const keysPath = 'keys';
const configPath = 'config';


/*
 * =============================================================
 * ======================= SCREENS =======================
 * =============================================================
 */

/**
 * Get the path of a Screens.
 *
 * @param {String} projectId The Project id.
 * @returns {String} The Screens path.
 */
const getScreensPath = projectId =>
  joinPaths(projectsPath, projectId, screensPath);

/**
 * Get the path of Translation Keys.
 *
 * @param {String} projectId The Project id.
 * @returns {String} The Screens path.
 */
const getTranslationKeysPath = (projectId, screenId) =>
  joinPaths(projectsPath, projectId, screensPath, screenId, keysPath);

/**
 * Get the path of Translation Key.
 *
 * @param {String} keyId The Key id.
 * @returns {String} The Screens path.
 */
const getTranslationKeyPath = keyId =>
  joinPaths(projectsPath, keyId, screensPath);

/**
 * Get the path of Config.
 *
 * @param {String} ConfigId.
 * @returns {String} The Config path.
 */
const getConfigPath = (projectId) =>
  joinPaths(projectsPath, projectId, configPath);

export {
    projectsPath,
    getScreensPath,
    getTranslationKeysPath,
    getTranslationKeyPath,
    getConfigPath
  };