import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import BreadcrumbIcon from './BreadcrumbIcon';
import LinkRouter from './LinkRouter';

const useStyles = makeStyles(() => ({
  link: {
    display: 'flex'
  }
}));

const Breadcrumb = ({ last, route: { icon: Icon, label, path } }) => {
  const classes = useStyles();

  const getIcon = () =>
    Icon && (
      <BreadcrumbIcon>
        <Icon fontSize="small" />
      </BreadcrumbIcon>
    );

  const getLabel = () => <span>{label}</span>;

  return last ? (
    <Typography className={classes.link} color="textPrimary">
      {getIcon()}
      {getLabel()}
    </Typography>
  ) : (
    <LinkRouter className={classes.link} color="inherit" to={path}>
      {getIcon()}
      {getLabel()}
    </LinkRouter>
  );
};

Breadcrumb.propTypes = {
  last: PropTypes.bool.isRequired,
  route: PropTypes.object.isRequired
};

export default Breadcrumb;
