import { getFirestorePipe } from 'ato-broker';
import {
  getTranslationKeysPath
} from '../util/collectionPaths';

/**
 * The Project service that exports functions for fetching, manipulating and deleting the Project Collection and it's Documents.
 *
 * @class
 * @category services
 * @alias ProjectService
 * @param {Service} service A generic service instance.
 * @returns {Object} The Project service.
 */
const getTranslationService = () => {
  const firestore = getFirestorePipe();

  /**
   * Get keys for specific screen.
   *
   * @param {String} screenId The  Screen id.
   * @returns {Promise<Object>} A Promise that resolves to the Project if it exists.
   */
  const getTranslationKeys = (projectId, screenId) =>
    firestore.getDocuments(getTranslationKeysPath(projectId, screenId), {});
  
  /**
   * Get specific Translation key.
   * 
   * @param {String} projectId The Project id.
   * @param {String} screenId The  Screen id.
   * @param {String} keyId The  Key id.
   * @returns {Promise<Object>} A Promise that resolves to the Project if it exists.
   */
  const getTranslationKey = (projectId, screenId, keyId) =>
    firestore.getDocument(getTranslationKeysPath(projectId, screenId), keyId);
  
  /**
   * Update Translation key.
   * 
   * @param {String} projectId The Project id.
   * @param {String} screenId The  Screen id.
   * @param {Object} data The Project data to update.
   * @returns {Promise} returns a promise, resolves to true if edit is successful
   */
  const updateTranslationKey = (projectId, screenId, keyId, translations) =>{
    return firestore.editDocument(getTranslationKeysPath(projectId, screenId), keyId, translations)};


  return {
    getTranslationKeys,
    getTranslationKey,
    updateTranslationKey
  };
};

export default getTranslationService;
