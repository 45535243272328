import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const Heading = ({ children, size }) => {
  const headingSize = size;
  const component = `h${headingSize}`;

  return (
    <Typography component={component} variant={component}>
      {children}
    </Typography>
  );
};

Heading.propTypes = {
  children: PropTypes.any,
  size: PropTypes.number.isRequired
};

Heading.defaultProps = {
  size: 3
};

export default Heading;
