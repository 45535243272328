import React from 'react'
import { connect } from 'react-redux'
import { AuthActions } from '../../store';
import Button from '@material-ui/core/Button';

const SignedInLinks = (props) => {
  return (
    <div>
      <Button onClick={props.signOut} href="/" color="inherit">Log out</Button>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  const { signOut } = AuthActions;
  return {
    signOut: () => dispatch(signOut())
  }
}

export default connect(null, mapDispatchToProps)(SignedInLinks)
