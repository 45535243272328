export const camelCaseToTitleCase = value =>
  value
    .replace(/([A-Z])/g, match => ` ${match}`)
    .replace(/^./, match => match.toUpperCase());

export const clamp = (number, min, max) =>
  Math.min(Math.max(parseInt(number), min), max);

export const createCopyWithoutId = obj =>
  (({ id, ...others }) => ({ ...others }))(obj);

export const fieldToColumn = (field, options) => ({
  name: field,
  label: camelCaseToTitleCase(field),
  options
});

export const fieldsToColumns = fields => fields.map(fieldToColumn);

export const joinPaths = (...paths) => paths.join('/');
