import Types from './actionTypes';

const initialState = {
  translationkeys: [],
  messages: [],
  translationkey: '',
  loading: false,
  error: undefined,
  docSavedStatus: true
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case Types.SET_DOC_STATUS:
      return {
        ...state,
        docSavedStatus: payload
      };
    case Types.GET_TRANSLATION_KEYS:
      return {
        ...state,
        translationkeys: payload,
        error: undefined
      };
      case Types.GET_TRANSLATION_KEY:
      return {
        ...state,
        translationkey: payload,
        error: undefined
      };
      case Types.UPDATE_TRANSLATION_KEY_SUCCESS:
      return {
        ...state,
        translationkey: payload,
        translationkeys: [...state.translationkeys.map(item => {
          if (item.id !== payload.id) {
            // This isn't the item we care about - keep it as-is
            return item
          }
          // Otherwise, this is the one we want - return an updated value
          return {
            ...item,
            ...payload
          }
        })],
        error: undefined,
        docSavedStatus: true
      };
      case Types.UPDATE_TRANSLATION_KEY_FAILURE:
      return {
        ...state,
        error: payload,
        docSavedStatus: false
      };
      case Types.EXPORT_PROJECT_TRANSLATIONS_SUCCESS:
      return {
        ...state,
        export: payload,
        error: undefined
      };
      case Types.EXPORT_PROJECT_TRANSLATIONS_FAILURE:
      return {
        ...state,
        export: undefined,
        error: payload
      };
      case Types.RESET:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
