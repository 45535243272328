import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom'
import Navbar from './components/layout/Navbar'
import { CssBaseline} from '@material-ui/core';
import { connect } from 'react-redux'
import Router from './Router';
import SimpleBreadcrumbs from './components/SimpleBreadcrumbs'


class App extends Component {
  render() {
    return (
      <BrowserRouter>
      <CssBaseline />
        <div className="App">
          <Navbar />
          {this.props.authUser ? <SimpleBreadcrumbs /> : null}
          <Router />
        </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  authUser: auth.authUser
});

export default connect(mapStateToProps)(App);
