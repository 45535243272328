import { getTranslationService, getFunctionService } from '../../services';
import * as Actions from './actions';

/**
 * Get the Translation Keys.
 *
 * @returns {Promise<Array<Object>>} A Promise that resolves to an Array of Translation Keys.
 */
const getTranslationKeys = (projectId, screenId) => (dispatch) => {
  const translationservice = getTranslationService();

  return translationservice.getTranslationKeys(projectId, screenId).then(keys => {
    dispatch(Actions.getTranslationKeysSuccess(keys));
  });
};

/**
 * Get the Translation Key.
 *
 * @returns {Promise<Array<Object>>} A Promise that resolves to a document of Translation Key.
 */
const getTranslationKey = (projectId, screenId, keyId) => (dispatch) => {
  const translationservice = getTranslationService();

  return translationservice.getTranslationKey(projectId, screenId, keyId).then(key => {
    dispatch(Actions.getTranslationKeySuccess(key));
  });
};

/**
 * Update the Translation Key.
 *
 * @returns {Promise<Array<Object>>} A Promise that resolves to a document of Translation Key.
 */
const updateTranslationKey = (projectId, screenId, keyId, translations) => (dispatch) => {
  const translationservice = getTranslationService();
  return translationservice.updateTranslationKey(projectId, screenId, keyId, translations).then(key => {
    dispatch(Actions.updateTranslationKeySuccess(key));
  }).catch(error => dispatch(Actions.updateTranslationKeyFailure(error)));
};

/**
 * Export Project Translations
 *
 * @returns {Promise<Array<Object>>} A Promise that resolves if Translations exported.
 */
const exportProjectTranslations = (projectId, env) => (dispatch) => {
  const functionsService = getFunctionService();
  const params = {
    projectId,
    env
  };
  return functionsService
    .callCloudFunction('exportProjectTranslations', params)
    .then(response =>
      dispatch(Actions.exportProjectTranslationsSuccess(response))
    )
    .catch(error => dispatch(Actions.exportProjectTranslationsFailure(error)));
};


export * from './actions';

export {
  getTranslationKeys,
  getTranslationKey,
  updateTranslationKey,
  exportProjectTranslations
};
