import React from 'react'
import Button from '@material-ui/core/Button';

const SignedOutLinks = () => {
  return (
    <div>
      <Button href="/signin" color="inherit">Login</Button>
    </div>
  )
}

export default SignedOutLinks