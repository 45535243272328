import React, {Component} from 'react';



class ImageUpload extends Component {
  constructor(props) {
    
    super(props);
    
    this.state = {
      image: null,
      url: '',
      progress: 0
    }
    this.handleChange = this
      .handleChange
      .bind(this);
      this.handleUpload = this.handleUpload.bind(this);
  }
  
  
  handleChange = e => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      this.setState(() => ({image}));
    }
  }
  handleUpload = () => {
    const { projectId, screenId, keyId } = this.props.params;
    
      const {image} = this.state;
      const filepath = keyId ? `screenshots/${projectId}/${screenId}_${keyId}.PNG`: `screenshots/${projectId}/${screenId}.PNG`;


      this.props.storage.monitoredUpload(filepath, image, {
        onProgres: progress => {
          this.setState({progress});
        },
        onError: console.log,
        onComplete: isComplete => {

          this.props.storage.getLinkForFile(filepath).then(url => {
            this.props.setUrl(url);
        })
          
        }
      })
  }
  render() {
    const style = {
      height: '5vh',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    };
    return (
      <div style={style}>
      <progress value={this.state.progress} max="100"/>
      <br/>
        <input type="file" onChange={this.handleChange}/>
        <button onClick={this.handleUpload}>Upload</button>
        <br/>
      </div>
    )
  }
}

export default ImageUpload;