
import { connect } from 'react-redux'
import { AuthActions } from '../../store';
import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Form, TextInput }  from '../../components';
import { Field } from 'react-final-form';
import { paths } from '../../routing';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.arion.run">
        Arion
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const SignIn = ({ signIn, history, location, error }) => {
  const classes = useStyles();

  const initialValues = {
    email: '',
    password: ''
  };

  const validate = ({ email, password }) => {
    const errors = {};

    if (!email) errors.email = 'Required';

    if (!password) errors.password = 'Required';

    return errors;
  };

  const onSubmit = ({email, password}) => {
    const path =
      location && location.state && location.state.from
        ? location.state.from
        : paths.dashboardPath;

    signIn(email, password).then(() => history.push(path));
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        
        <Form
            initialValues={initialValues}
            submitLabel="Sign in"
            validate={validate}
            onSubmit={onSubmit}
          >
            
            <Field
              autoComplete="email"
              id="email"
              label="Email"
              name="email"
              maxLength={50}
              required
              component={TextInput}
            />
            <Field
              autoComplete="current-password"
              id="password"
              label="Password"
              name="password"
              maxLength={20}
              required
              type="password"
              component={TextInput}
            />
            {error && (
              <div className={classes.errorMessage}>
                <Typography color="error">{error}</Typography>
              </div>
            )}
          </Form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
const mapStateToProps = ({ auth }) => ({
  authUser: auth.authUser,
  loading: auth.loading
});

const mapDispatchToProps = dispatch => {
  const { signIn } = AuthActions;

  return {
    dispatch,
    signIn: (email, password) => dispatch(signIn(email, password))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
