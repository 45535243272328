import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { Provider } from 'react-redux'
import initBrokerPipes from './pipes';
import { initStore } from './store';


const Root = ({ store }) => (
  <Provider store={store}><App /></Provider>
);


const render = async store => {
  const target = document.getElementById('root');

  ReactDOM.render(<Root store={store} />, target);
};


const init = async () => {

  await initBrokerPipes();

  const store = initStore();

  render(store);
};

init();

registerServiceWorker();