import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import {
  initFirebaseAuth,
  initFirestore,
  initFirebaseFunctionsPipe,
  initFirebaseStoragePipe
} from 'ato-broker';
import config from './config';

const initBrokerPipes = async () => {
  const fireConf = {
    ...config.firebase,
    persistence: true
  };

  const app = await firebase.initializeApp(fireConf, fireConf.projectId);

  const auth = app.auth();
  const firestore = app.firestore();
  const functions = app.functions('europe-west1');
  const storage = app.storage();
  const authPipe = initFirebaseAuth(auth);

  const firestorePipe = initFirestore(firestore);
  const functionsPipe = initFirebaseFunctionsPipe(functions);
  const storagePipe = initFirebaseStoragePipe(storage);

  return {
    authPipe,
    firestorePipe,
    functionsPipe,
    storagePipe
  };
};

export default initBrokerPipes;
