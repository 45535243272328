import Types from './actionTypes';

const initialState = {
  projects: [],
  screens: [],
  config:{apiKey:'',authDomain:'', databaseURL:'', projectId:'',storageBucket:''},
  loading: false,
  error: undefined,
  success: '',
  docSavedStatus: true
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case Types.SET_DOC_STATUS:
      return {
        ...state,
        docSavedStatus: payload
      };
    case Types.SET_CONFIG_PROPERTY:
      return {
        ...state,
        config: {...state.config, ...payload}
      };
    case Types.GET_PROJECTS:
      return {
        ...state,
        projects: payload,
        error: undefined
      };
      case Types.GET_SCREENS:
      return {
        ...state,
        screens: payload,
        error: undefined
      };
      case Types.UPDATE_PROJECT_STAT_SUCCESS:
        return state;
      case Types.UPDATE_SCREEN_STAT_SUCCESS:
          return state;
      case Types.ADD_CONFIG_PROJECT_SUCCESS:
        return {...state,
        error: undefined
        };
      case Types.ADD_CONFIG_PROJECT_FAILURE:
        return {
          ...state,
          error: payload
        };
      case Types.GET_CONFIG_PROJECT_SUCCESS:
        return {
          ...state,
          config: payload,
          error: undefined
        };
      case Types.GET_CONFIG_PROJECT_FAILURE:
        return {
          ...state,
          config: initialState.config,
        };
      case Types.UPDATE_CONFIG_PROJECT_SUCCESS:
        return {
          ...state,
          config: payload,
          error: undefined,
          docSavedStatus:true
        };
      case Types.UPDATE_CONFIG_PROJECT_FAILURE:
        return {
          ...state,
          error: payload,
          docSavedStatus:false
        };
      case Types.RESET:
        return initialState;
    default:
      return state;
  }
};

export default reducer;
