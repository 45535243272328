const config = {
  firebase: {
    apiKey: "AIzaSyB4UC1Y4Kv3WLjkt77Doiu6qeWR_6EW4jQ",
    authDomain: "translation-system-cb145.firebaseapp.com",
    databaseURL: "https://translation-system-cb145.firebaseio.com",
    projectId: "translation-system-cb145",
    storageBucket: "translation-system-cb145.appspot.com",
    messagingSenderId: "274486143932",
    appId: "1:274486143932:web:f6f4f2df1f9a96bac199b9"
  }
};

export default config;
