import Types from './actionTypes';

const initialState = {
  authUser: undefined,
  authUserId: undefined,
  isAdmin: false,
  error: undefined,
  loading: true
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case Types.SET_AUTH_USER: {
      return {
        ...state,
        authUser: payload,
        authUserId: payload ? payload.uid : undefined,
        isAdmin: payload ?  payload.isAdmin : undefined,
        error: undefined
      };
    }
    case Types.SET_ERROR: {
      return {
        ...state,
        error: payload
      };
    }
    case Types.SET_LOADING: {
      return {
        ...state,
        loading: payload
      };
    }
    default:
      return state;
  }
};

export default reducer;
