export default {
    SET_DOC_STATUS: 'SET_DOC_STATUS',
    SET_CONFIG_PROPERTY: 'SET_CONFIG_PROPERTY',
    GET_PROJECTS: 'GET_PROJECTS',
    GET_SCREENS: 'GET_SCREENS',
    UPDATE_PROJECT_STAT_SUCCESS: 'UPDATE_PROJECT_STAT_SUCCESS',
    UPDATE_SCREEN_STAT_SUCCESS: 'UPDATE_SCREEN_STAT_SUCCESS',
    ADD_CONFIG_PROJECT_SUCCESS: 'ADD_CONFIG_PROJECT_SUCCESS',
    ADD_CONFIG_PROJECT_FAILURE: 'ADD_CONFIG_PROJECT_FAILURE',
    GET_CONFIG_PROJECT_SUCCESS: 'GET_CONFIG_PROJECT_SUCCESS',
    GET_CONFIG_PROJECT_FAILURE: 'GET_CONFIG_PROJECT_FAILURE',
    UPDATE_CONFIG_PROJECT_SUCCESS: 'UPDATE_CONFIG_PROJECT_SUCCESS',
    UPDATE_CONFIG_PROJECT_FAILURE: 'UPDATE_CONFIG_PROJECT_FAILURE',
    RESET: 'RESET'
  };
  