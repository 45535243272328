import React from 'react'
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  filled: {
    textDecoration: 'line-through'
  },
}));

const isfilled = target => {
  for(var member in target) {
    if (target[member] === null || target[member] === '')
    {
      return false;
    }
  }
  return true;
}

const TranslationList = ({translationkeys, loadLangValues}) => {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  return (
    <div>
          
      { translationkeys && translationkeys.map(transkey => {
        
        return (
          <div  key={transkey.id}>
            <ListItem
                  id={transkey.id}
                  button
                  selected={selectedIndex === transkey.id}
                  onClick={event => {if(loadLangValues(event)) handleListItemClick(event, transkey.id); }}
                >
                  <Typography
                    variant="subtitle1"
                    id={transkey.id}
                    className={isfilled(transkey) ? classes.filled: null}
                    >
                      {transkey.id}
                    </Typography>
                </ListItem>
            <Divider />            
          </div>
        )
      })}  
      
    </div>
  )
}

export default (TranslationList)