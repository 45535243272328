import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import SignIn from './views/auth/SignIn'
import Dashboard from './views/dashboard/Dashboard'
import Screen from './views/screen/Screen'
import Export from './views/screen/Export'
import Translation from './views/translation/Translation'
import { connect } from 'react-redux'
import { AuthActions } from './store';
import { SplashScreen } from './components';
import { paths } from './routing';

const Router = ({init, authUser, loading}) =>{
    useEffect(() => {
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
      const getRedirectPath = ({ location }) =>
    location && location.state && location.state.from
      ? location.state.from
      : paths.dashboardPath;
    return (
    <Switch>      
      <Switch>
            <PrivateRoute exact path='/'component={Dashboard} authUser={authUser} />
            <PrivateRoute exact path='/project/:projectId' component={Screen} authUser={authUser} />
            <PrivateRoute exact path='/project/:projectId/export' component={Export} authUser={authUser} />
            <PrivateRoute exact path='/project/:projectId/screen/:screenId' component={Translation} authUser={authUser} />
            
            <Route
                path={paths.loginPath}
                exact
                render={props =>
                loading ? (
                    <SplashScreen />
                ) : !authUser ? (
                    <SignIn {...props} />
                ) : (
                    <Redirect to={getRedirectPath(props)} />
                )
                }
            />
          </Switch>
    </Switch>
  
)};

const PrivateRoute = ({ component: Component, authUser, ...rest }) => {  
return(
  <Route
    {...rest}
    render={props =>
        authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
              to={{
                pathname: paths.loginPath,
                state: { from: props.location.pathname }
              }}
            />
      )
    }
  />
)};

const mapStateToProps = ({ auth }) => ({
    authUser: auth.authUser,
    loading: auth.loading
  });

const mapDispatchToProps = dispatch => {
    const { init } = AuthActions;
  
    return {
      dispatch,
      init: () => dispatch(init())
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(Router)