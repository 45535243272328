import React from 'react'
import TaskProgress from '../../components/TaskProgress'

const ScreenSummary = ({screen}) => {
  return (
    <div className="card z-depth-0 project-summary">
      <TaskProgress title={screen.id} done={screen.translated} total={screen.total} />
      
    </div>
  )
}

export default ScreenSummary
