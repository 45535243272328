import Types from './actionTypes';

const setDocStatus = (status) => ({
  type: Types.SET_DOC_STATUS,
  payload: status
});

const getTranslationKeysSuccess = translationKeys => ({
  type: Types.GET_TRANSLATION_KEYS,
  payload: translationKeys
});

const getTranslationKeySuccess = translationKey => ({
  type: Types.GET_TRANSLATION_KEY,
  payload: translationKey
});

const updateTranslationKeySuccess = (translationKey) => ({
  type: Types.UPDATE_TRANSLATION_KEY_SUCCESS,
  payload: translationKey
});

const updateTranslationKeyFailure = (error) => ({
  type: Types.UPDATE_TRANSLATION_KEY_FAILURE,
  payload: error
});

const exportProjectTranslationsSuccess = (payload) => ({
  type: Types.EXPORT_PROJECT_TRANSLATIONS_SUCCESS,
  payload: payload
});

const exportProjectTranslationsFailure = (error) => ({
  type: Types.EXPORT_PROJECT_TRANSLATIONS_FAILURE,
  payload: error
});

const cleanUp = () => ({
  type: Types.RESET
});

export {
  setDocStatus,
  getTranslationKeysSuccess,
  getTranslationKeySuccess,
  updateTranslationKeySuccess,
  updateTranslationKeyFailure,
  exportProjectTranslationsSuccess,
  exportProjectTranslationsFailure,
  cleanUp
};
