import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import Heading from './Heading';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2, 3),
    minHeight: theme.spacing(40)
  }
}));

const Tile = ({ children, title }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper} elevation={4}>
      {typeof title === 'string' ? <Heading size={6}>{title}</Heading> : title}
      {children}
    </Paper>
  );
};

Tile.propTypes = {
  children: PropTypes.any,
  title: PropTypes.any
};

export default Tile;
