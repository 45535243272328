import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ProjectActions, TranslationActions } from '../../store';
import TranslationList from './TranslationList'
import TranslationValues from './TranslationValues'
import {  Grid, Paper, Typography } from '@material-ui/core';
import Mobile from './Mobile'

import { Prompt } from 'react-router'
import  { Link } from 'react-router-dom'


const styles = {
    Paper: {
      padding: 20,
      marginTop: 10,
      marginBottom: 10,
      height: 650,
      overflowY: 'auto'
    }
  }

const countObjDiff = (newEntry, oldEntry) =>
{
  let diff = 0;
  Object.keys(newEntry).map(key => {
    let added = newEntry[key] && !oldEntry[key];
    let removed = !newEntry[key] && oldEntry[key];
    if(added)
    {
      diff++;
    }
    else if(removed)
    {
      diff--;
    }

});
return diff;
}

const Translation = ({setDocStatus,
getTranslationKey,
getTranslationKeys,
translationkeys,
translationkey,
updateTranslationKey,
projectId,
screenId,
error,
docSavedStatus,
cleanUp, 
updateProjectStat,
updateScreenStat,
          }) => {

  const [translations, setTranslations] = useState('');
  const [keyId, setKeyId] = useState('');

  const params = {
    projectId,
    screenId,
    keyId
  }

  useEffect(() => {
    getTranslationKeys();

    return () => {
      cleanUp();
    };
  }, []);


  const handleSubmit = (e) => {
    e.preventDefault();
    let statDiff = countObjDiff(translations, translationkey);
    updateTranslationKey(keyId, translations);
    updateProjectStat(statDiff);
    updateScreenStat(statDiff);
    setTranslations('');
    }
  const handleChange = (e) =>{
    setTranslations({
      ...translations,  //take existing key-value pairs and use them in our new state,
      [e.target.id]: e.target.value   //define new key-value pair with new uuid and [].
      });
      setDocStatus(false);
  }
  const loadLangValues = (e) => {
    if (!docSavedStatus && !window.confirm('You have unsaved changes, are you sure you want to leave?'))
    {
      return false;
    }
    else
    {
      getTranslationKey(e.target.id);
      setKeyId(e.target.id);
      setTranslations('');
      setDocStatus(true);
      return true;
    }
    
     
  }

  return (
    <div>
      <Prompt
            when={!docSavedStatus}
            message='You have unsaved changes, are you sure you want to leave?'
          />
                <Grid container  spacing={2}>
              <Grid item sm={3}>
                <Paper style={styles.Paper}>
                  <Typography
                      variant="subtitle1">
                      Keys
                  </Typography>
                <TranslationList loadLangValues={loadLangValues} translationkeys={translationkeys} />
                </Paper>
              </Grid>
              <Grid item sm>
                <Paper style={styles.Paper}>
                  <Typography
                    variant="subtitle1">
                      Translations
                  </Typography>
                    <TranslationValues handleSubmit={handleSubmit} handleChange={handleChange} translationkey={translationkey} btnDisabled={docSavedStatus} />
                    {error && (
                      <div>
                        <Typography color="error">{error.code}</Typography>
                      </div>
                    )}
                  
                </Paper>
              </Grid>
              <Grid item sm={4}>
                <Paper style={styles.Paper}>
                <Typography
                    variant="subtitle1">
                      Preview & &nbsp;                       
                      <Link
                        to={"/project/" + projectId + "/export"}>
                        Export Translations to app
                      </Link>
                  </Typography>
                  
                      
                <Mobile params={params} />
                
                </Paper>
              </Grid>
            </Grid>


      </div>
  );
};

const mapStateToProps = ({ translation }) => ({
    translationkeys: translation.translationkeys,
    translationkey: translation.translationkey,
    error: translation.error,
    docSavedStatus: translation.docSavedStatus
  });

  const mapDispatchToProps = (dispatch, ownProps) => {
    const {updateProjectStat, updateScreenStat} = ProjectActions;
    const { getTranslationKeys, getTranslationKey, updateTranslationKey, cleanUp, setDocStatus} = TranslationActions;
    const {
        match: {
          params: { projectId, screenId }
        }
      } = ownProps;
    return {
    dispatch,
    getTranslationKeys: () => dispatch(getTranslationKeys(projectId, screenId)),
    getTranslationKey: (keyId) => dispatch(getTranslationKey(projectId, screenId, keyId)),
    updateTranslationKey: (keyId, translations) => dispatch(updateTranslationKey(projectId, screenId, keyId, translations)),
    updateProjectStat: (statDiff) => dispatch(updateProjectStat(projectId, statDiff)),
    updateScreenStat: (statDiff) => dispatch(updateScreenStat(projectId, screenId, statDiff)),
    projectId: projectId,
    screenId: screenId,
    cleanUp: () => dispatch(cleanUp()),
    setDocStatus: (status) => dispatch(setDocStatus(status)),
    };
};

export default connect(
    mapStateToProps, mapDispatchToProps
)(Translation);