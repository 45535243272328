import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Form as FinalForm } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Paper } from '@material-ui/core';
import { Cancel as CancelIcon, Delete as DeleteIcon } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3, 4)
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(1, 0)
  },
  submitButton: {
    marginRight: theme.spacing(2)
  },
  icon: {
    marginLeft: theme.spacing(1)
  }
}));

const Form = ({
  children,
  initialValues,
  loading,
  submitIcon: SubmitIcon,
  submitLabel,
  resetOnSubmit,
  validate,
  onSubmit,
  onCancelClick,
  onDeleteClick
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper} elevation={4}>
      <FinalForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
        render={({ dirty, handleSubmit, invalid, submitting, form }) => (
          <form
            onSubmit={
              resetOnSubmit
                ? data => {
                    handleSubmit(data);
                    form.reset();
                  }
                : handleSubmit
            }
          >
            {children}
            <div className={classes.buttons}>
              <div>
                <Button
                  className={clsx(classes.button, classes.submitButton)}
                  color="primary"
                  disabled={loading || !dirty || invalid || submitting}
                  type="submit"
                  variant="contained"
                >
                  {submitLabel}
                  {SubmitIcon && <SubmitIcon className={classes.icon} />}
                </Button>
                {onCancelClick && (
                  <Button
                    className={classes.button}
                    variant="outlined"
                    onClick={onCancelClick}
                  >
                    Cancel
                    <CancelIcon className={classes.icon} />
                  </Button>
                )}
              </div>
              {onDeleteClick && (
                <Button
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={onDeleteClick}
                >
                  Delete
                  <DeleteIcon className={classes.icon} />
                </Button>
              )}
            </div>
          </form>
        )}
      />
    </Paper>
  );
};

Form.propTypes = {
  children: PropTypes.any.isRequired,
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  submitIcon: PropTypes.any,
  submitLabel: PropTypes.string,
  validate: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func,
  onDeleteClick: PropTypes.func
};

export default Form;
