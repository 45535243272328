import { getProjectService } from '../../services';
import * as Actions from './actions';

/**
 * @category store
 * @module ProjectStore
 */

/**
 * Get the Projects.
 *
 * @returns {Promise<Array<Object>>} A Promise that resolves to an Array of Projects.
 */
const getProjects = () => (dispatch) => {
  const projectService = getProjectService();

  return projectService.getProjects().then(projects => {
    dispatch(Actions.getProjectsSuccess(projects));
    return projects;
  });
};

/**
 * Get the Screens.
 *
 * @returns {Promise<Array<Object>>} A Promise that resolves to an Array of Screens.
 */
const getScreens = (projectId) => (dispatch) => {
  const projectService = getProjectService();

  return projectService.getScreens(projectId).then(screens => {
    dispatch(Actions.getScreensSuccess(screens));
    return screens;
  });
};


/**
 * Update the Project Statistics.
 *
 * @returns {Promise<Array<Object>>} A Promise that resolves to a document of project.
 */
const updateProjectStat = (projectId, statDiff) => (dispatch) => {
  const projectService = getProjectService();
  return projectService.updateProjectStat(projectId, statDiff).then(doc => {
    dispatch(Actions.updateProjectStatSuccess(doc));
  });//.catch(error => dispatch(Actions.updateTranslationKeyFailure(error)));
};


/**
 * Update the Screen Statistics.
 *
 * @returns {Promise<Array<Object>>} A Promise that resolves to a document of screen.
 */
const updateScreenStat = (projectId, screenId, statDiff) => (dispatch) => {
  const projectService = getProjectService();
  return projectService.updateScreenStat(projectId, screenId, statDiff).then(doc => {
    dispatch(Actions.updateScreenStatSuccess(doc));
  });//.catch(error => dispatch(Actions.updateTranslationKeyFailure(error)));
};


/**
 * Get or Create the configuration of project.
 *
 * @returns {Promise<Array<Object>>} A Promise that resolves to a document of config .
 */
const getOrCreateConfigProject = (projectId, configId, newConfig) => (dispatch) => {
  const projectService = getProjectService();

  return projectService.getConfigProject(projectId, configId).then(config => {
    //if config not exists create one
    if(!config)
    {
      dispatch(Actions.getConfigProjectFailure("Config not exists"));
      return projectService.addConfigProject(projectId, configId, newConfig)
      .then(() => dispatch(Actions.addConfigProjectSuccess(config)))
      .catch(error => dispatch(Actions.addConfigProjectFailure(error)));
    }
    
    dispatch(Actions.getConfigProjectSuccess(config));
    
  }).catch(error => dispatch(Actions.getConfigProjectFailure(error)));
};

/**
 * Update the configuration of project.
 *
 * @returns {Promise<Array<Object>>} A Promise that resolves to a document of config project.
 */
const updateConfigProject = (projectId, configId, configProperties) => (dispatch) => {
  const projectService = getProjectService();
  console.log(configProperties);
  return projectService.updateConfigProject(projectId, configId, configProperties).then(config => {
    dispatch(Actions.updateConfigProjectSuccess(config));
  }).catch(error => dispatch(Actions.updateConfigProjectFailure(error)));
};

export * from './actions';

export {
  getProjects,
  getScreens,
  updateProjectStat,
  updateScreenStat,
  getOrCreateConfigProject,
  updateConfigProject
};
